import { getDefaultStore } from "jotai"
import { RejectEntryRequest } from "../../__generated__/proto/itech/motorist/pksha/v1/pksha_api"
import { authAtom } from "../../atoms"
import { AuthError, ReservationServiceType } from "../services"
import { UseCase } from "./use-case"

export class RejectEntryUseCase
  implements UseCase<RejectEntryRequest, Promise<boolean>>
{
  constructor(readonly reservationService: ReservationServiceType) {}

  async call(request: RejectEntryRequest): Promise<boolean> {
    const token = getDefaultStore().get(authAtom)
    if (!token) {
      throw new AuthError()
    }

    const result = await this.reservationService.cancelReservation(
      token,
      request
    )
    if (result.isFailure()) {
      console.error(result.value)
      return false
    }
    return true
  }
}
